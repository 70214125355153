h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'URWArabic', Helvetica, Arial, serif !important;
}
html {
  overflow-x: hidden;
}
[data-background] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fc-event-time {
  display: none !important;
}
::-webkit-scrollbar {
}
::-webkit-scrollbar-button {
}
::-webkit-scrollbar-track {
}
::-webkit-scrollbar-track-piece {
}
::-webkit-scrollbar-thumb {
}
::-webkit-scrollbar-corner {
}
::-webkit-resizer {
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-lines: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 17px;
  font-family: 'URWArabic', Helvetica, Arial, serif !important;
}

.scrollable-card {
  height: 70vh;
  overflow-y: scroll;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  opacity: 0.6;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.committee-tag {
  background-color: #d2edf7;
  border-radius: 7px;
  max-width: fit-content;
  padding: 7px;
}

.import-img {
  border-radius: 25px;
  background-color: #f9fcfe;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.05);
  padding: 15px;
  cursor: pointer;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  z-index: 999999;
}

.jqvmap-region {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}

.jqvmap-zoomin {
  top: 10px;
}

.jqvmap-zoomout {
  top: 30px;
}

.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}

.btn-success {
  color: #ffffff;
}

.disabled,
:disabled {
  color: #ffffff !important;
}

.lightbox {
  margin-top: 65px !important;
  display: flex !important;
  flex-direction: column-reverse;
}

.popup {
  flex: 1 !important;
}
.toast-success {
  background-color: #72a545 !important;
}
.toast-danger {
  background-color: #d5472d !important;
}
.toast-blue {
  background-color: #58c0e6 !important;
}
.toast-icon {
  color: white;
  width: 30px !important;
  height: 30px;
  border-radius: 5px;
}
.icon {
  position: relative;
  top: calc(50% - 10px);
}

.html-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: '#6B6B6B' !important;
  align-items: center;
}

.close-success {
  color: #72a545 !important;
}
.close-danger {
  color: #d5472d !important;
}

.close-blue {
  color: #58c0e6 !important;
}
.toast-text {
  margin: 0 20px;
}
/*----------------------------- multiselect style -----------------------------*/
.multiselect-dropdown {
  width: 140% !important;
  max-width: 140% !important;
}

.multiselect-dropdown .dropdown-btn {
  justify-content: flex-start !important;
  display: flex !important;
  flex-direction: row !important;
  border: 1px solid #00000073 !important;
  text-align: right !important;
  border-radius: 15px !important;
}
.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #888888 !important;
  background: #888888 !important;
  font-size: xx-small !important;
  max-width: inherit !important;
}
.multiselect-dropdown .dropdown-btn .selected-item a {
  margin: 0px 2px !important;
}
.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: 1px 1px #959595;
}
.multiselect-dropdown .dropdown-btn .dropdown-down {
  display: inline-block;
  top: 10px;
}
.multiselect-dropdown .dropdown-btn .dropdown-up {
  display: inline-block;
  width: 0;
  height: 0;
}
.dropdown-btn > span > span {
  padding-right: 5px !important;
}
.multiselect-dropdown .disabled > span {
  background-color: #eceeef;
}
.dropdown-list {
  border-radius: 20px !important;
  margin-top: 0px !important;
  box-shadow: 0px 0px 10px #72727228 !important;
}
.dropdown-list ul {
  padding: 0px;
  list-style: none;
}
.dropdown-list li {
  text-align: right !important;
}
li.multiselect-item-checkbox > div {
  color: #565656 !important;
}
.dropdown-list .filter-textbox {
  border-bottom: 1px solid #ccc;
}
.dropdown-list .filter-textbox input {
  border: 0px;
  width: 100%;
}
.dropdown-list .filter-textbox input:focus {
  outline: none;
}
.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
}
.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: #e63f3f !important;
  background-color: #f2f2f2;
}
.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
}
.multiselect-item-checkbox input[type='checkbox'] + div {
  font-weight: 100 !important;
}
.multiselect-item-checkbox input[type='checkbox'] + div:before {
  border-radius: 5px !important;
  content: '';
  color: #72a545 !important;
  border: 2px solid #8181819d !important;
}
.multiselect-item-checkbox input[type='checkbox'] + div:after {
  box-sizing: content-box;
  content: '';
  background-color: #72a545 !important;
}
.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
  border-color: #ccc;
}
.multiselect-item-checkbox
  input[type='checkbox']:disabled:focus
  + div:before
  .multiselect-item-checkbox
  input[type='checkbox']:disabled:hover
  + div:before {
  background-color: inherit;
}
.multiselect-item-checkbox input[type='checkbox']:disabled:checked + div:before {
  background-color: #ccc;
}
.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  transform: rotate(-45deg) scale(0);
}
.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: #72a545 !important;
}
.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  transform: rotate(-45deg) scale(1);
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  right: auto !important;
  left: 0px !important;
}

.dropdown-btn > span:last-of-type {
  text-align: center !important;
  padding-left: 4px !important;
  padding-right: 0px !important;
}
.multiselect-dropdown,
.multiselect-dropdown > div {
  width: 100% !important;
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #ffffff !important;
  }
}
.modal-backdrop.show {
  opacity: 0.1;
}
.modal-content {
  border: none;
  box-shadow: 0px 0px 20px 0 rgb(0 0 0 / 28%);
}
.modal-content {
  border-radius: 25px;
}
.close-btn {
  position: absolute;
  top: -0.5em;
  left: -0.5em;
  font-size: x-large;
  cursor: pointer;
  color: red;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}
.close-btn:hover {
  color: #565656;
  transform: scale(1.1);
}
.app-content.content {
  margin-top: 0px !important;
  padding-top: 85px;
}

.lb-close {
  position: absolute;
}
